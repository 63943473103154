<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-bullseye"></i> IP Blacklist</h2>

        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <button type="button" class="btn btn-primary mr-1" @click="fileUpload = true" ><i class="fa fa-upload" title="Check for Updates"></i> Upload Blacklist File</button>
                            <!--                            <button type="button" class="btn btn-success mr-1" @click="exportIps"><i class="fa fa-download" title="Export to File"></i> Export</button>-->
                        </div>
                    </div>
                </div>

                <div class="listing pt-3" style="background-color:white; margin-top:20px;">
                    <table class="table table-hover table-striped">
                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>Network Address</td>
                            <td>Subnet Mask</td>
                            <td>CIDR</td>
                            <td>Starts</td>
                            <td>Ends</td>
                            <td>Available Hosts</td>
                            <td>Status</td>
                            <!-- <td></td>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in ips" v-bind:key="data.id">
                            <td>{{data._id}}</td>
                            <td>{{data.networkAddress}}</td>
                            <td>{{data.subnetMask}}</td>
                            <td>/{{data.subnetMaskLength}}</td>
                            <td>{{data.firstAddress}}</td>
                            <td>{{data.lastAddress}}</td>
                            <td>{{ Number(data.numHosts).toLocaleString()}}</td>
                            <td>{{data.status}}</td>
                            <!--<td>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-primary">Actions</button>
                                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                        <span class="sr-only">Toggle Dropdown</span>
                                        <div class="dropdown-menu" role="menu">
                                            &lt;!&ndash;<a class="dropdown-item" @click="details(data)" ><span class="fa fa-eye" title="View"></span> Details</a>
                                            <div class="dropdown-divider"></div>&ndash;&gt;
                                            <a v-if="data.status !== 'disabled'" class="dropdown-item" @click="disable(data)"><span class="fa fa-times-circle" title="Disable IP Address"></span> Disable</a>
                                            <a v-else class="dropdown-item" @click="enable(data)"><span class="fa fa-check-circle" title="Enable IP address"></span> Enable</a>
                                            <a class="dropdown-item" @click="remove(data)"><span class="fa fa-trash" title="Delete IP address"></span> Delete</a>
                                        </div>
                                    </button>
                                </div>
                                <div class="btn-group icons">

                                </div>
                            </td>-->
                        </tr>
                        </tbody>
                    </table>
                </div>
                <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="spinner">
                        <b-spinner type="grow" label="Loading..." variant="primary"></b-spinner>
                        <h3>Fetching......</h3>
                    </div>
                    <div slot="no-more"><h3>No more results were found......</h3></div>
                    <div slot="no-results"><h3>No results were found......</h3></div>
                </infinite-loading>
            </div>
        </div>

        <b-modal id="file-upload-modal" size="sm" title="Upload Blacklist File" v-model="fileUpload">
            <template #default="{}">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="file" ref="fileUploader" @change="handleFileUpload" class="form-control-file">
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-footer="{ ok }">
                <button type="button" class="btn btn-sm btn-primary" @click="uploadFile"><i class="fa fa-upload"></i> Upload</button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: 'IpBlacklist',
    components: {
        InfiniteLoading,
    },
    data: function () {
        return {
            ips:[],
            infiniteId: +new Date(),
            max: 25,
            page: 1,
            searching: false,
            fileUpload: false,
            fileToUpload: null
        }
    },
    created: function(){
        this.$root.isAuth();
        this.$root.preloader = false;
        this.search();
        //window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        //window.removeEventListener("keydown",this.windowListener);
    },
    computed:{
    },
    methods:{
        /* windowListener: function(e){
             if(e.keyCode === 13 && !this.editor && !this.showReport && !this.searching){
                 this.search();
             }
         },*/
        disable: function(obj){
            swal({title:`Disable ${obj.ip}`, text:"Disabling this IP from the list will allow traffic to use it.", icon:"info", buttons:['No','Yes']}).then(function(val){
                if(!val) return

                var request = {'apikey': this.$root.apikey}
                this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/blacklist/disable/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Success!", text:"Your request was completed successfully", icon:"success"}).then(function(){
                            this.search()
                        }.bind(this))
                    } else {
                        //Handle errors
                        swal({title:"Oops!", text:"Unable to complete your request", icon:"error"})
                    }
                }.bind(this))
                    .catch(function(err){
                        this.$root.preloader = false
                        this.errormsg = err.message||"An unexpected error occurred"
                        swal({title:"System Error!", text: this.errormsg, icon:"error"})
                    }.bind(this))
            }.bind(this))
        },
        enable: function(obj){
            swal({title:`Enable ${obj.ip}`, text:"Enabling this IP from the list will stop traffic from using it.", icon:"info", buttons:['No','Yes']}).then(function(val){
                if(!val) return

                var request = {'apikey': this.$root.apikey}
                this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/blacklist/enable/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Success!", text:"Your request was completed successfully", icon:"success"}).then(function(){
                            this.search()
                        }.bind(this))
                    } else {
                        //Handle errors
                        swal({title:"Oops!", text:"Unable to complete your request", icon:"error"})
                    }
                }.bind(this))
                    .catch(function(err){
                        this.$root.preloader = false
                        this.errormsg = err.message||"An unexpected error occurred"
                        swal({title:"System Error!", text: this.errormsg, icon:"error"})
                    }.bind(this))
            }.bind(this))
        },
        remove: function(obj){
            //Handle removing the item
            swal({title:`Remove ${obj.ip}`, text:"Removing this IP from the list will allow traffic to use it.", icon:"info", buttons:['No','Yes']}).then(function(val){
                if(!val) return

                var request = {'apikey': this.$root.apikey}
                this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/blacklist/remove/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Success!", text:"Your request was completed successfully", icon:"success"}).then(function(){
                            this.search()
                        }.bind(this))
                    } else {
                        //Handle errors
                        swal({title:"Oops!", text:"Unable to complete your request", icon:"error"})
                    }
                }.bind(this))
                    .catch(function(err){
                        this.$root.preloader = false
                        this.errormsg = err.message||"An unexpected error occurred"
                        swal({title:"System Error!", text: this.errormsg, icon:"error"})
                    }.bind(this))
            }.bind(this))
        },
        search: function(){
            this.page = 0;
            this.infiniteId += 1;
            const request = {'apikey': this.$root.apikey};
            request.page = this.page;
            request.max = this.max
            axios.get(`${this.$root.serverUrl}/admin/blacklist`, {params:request}).then(function(resp){
                //Store the stats
                this.ips = (resp.data && resp.data.data.length > 0) ? resp.data.data : [];
            }.bind(this)).catch(function(err){
                swal({title:'Oops', text: err.message, icon: 'error'})
            }.bind(this))
        },
        infiniteHandler($state) {
            this.page += 1;
            const request = {'apikey': this.$root.apikey}
            request.page = this.page;
            request.max = this.max

            axios.get(`${this.$root.serverUrl}/admin/blacklist`, {params:request}).then(function(resp){
                $state.loaded();
                if (resp.data && resp.data.data.length > 0) {
                    this.ips.push(...resp.data.data);
                } else {
                    $state.complete();
                }
            }.bind(this)).catch(function(err){
                $state.complete();
                swal({title:'Oops', text: err.message, icon: 'error'})
            })
        },
        loadClients: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                //Store the stats
                this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        handleFileUpload(){
            this.fileToUpload = this.$refs.fileUploader.files[0];
        },
        uploadFile: function(){
            const request = new FormData();
            if(this.fileToUpload === null){
                swal({title:"Error", text: "a file needs to be selected", icon:"error"});
                return;
            }
            if(this.fileToUpload.type !== "text/plain"){
                swal({title:"Error", text: "a text file needs to be selected", icon:"error"});
            }
            request.append("file",this.fileToUpload);

            axios.post(`${this.$root.serverUrl}/admin/blacklist/upload`, request, {headers: { apikey: this.$root.apikey, contentType: 'multipart/form-data'}}).then(() => {
                swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(() => {
                    this.fileUpload = false;
                    this.search();
                });
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: 'Communication Error', icon:"error"});
                this.$root.preloader = false;
            });

            /*axios({
                url:`${this.$root.serverUrl}/admin/blacklist/upload`,
                data:request,
                responseType:'json',
                method:'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(){
                swal({title:"Success!", text: "file upload was successful", icon:"success"});
            })
            .catch(function(resp){
                swal({title:"Error", text: resp.error, icon:"error"});
            });*/
        },
        exportIps: function(){}
    },
    watch:{
        "filter.country": function(){
            this.filter.state = "";
            this.filter.city = "";
        },
        "filter.state": function(){
            this.filter.city = "";
        }
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
.rep-btn{
    margin-right: 3px;
}
.table{
    min-height: 230px;
}
</style>
